<template>
  <div class="login-container">
    <div class="platform">
      <div class="container-bg">
        <p>
          <span class="ball1"></span>
        </p>
        <p>
          <span class="ball2"></span>
        </p>
        <p>
          <span class="ball3"></span>
        </p>
      </div>
      <div class="platform-container">
        <div class="header">
          <div class="center">
            <section>
              <img src="../../assets/images/home/solutions.png" alt srcset />
            </section>
            <section style="width: 24vw">
              <h3>英海瑞特「ITS」服务平台</h3>
              <p>INHERITECH INTELLIGENT TRANSPOORTATION SYSTEM</p>
            </section>
          </div>
        </div>
        <div class="main">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-position="left"
            class="demo-ruleForm login-page demo-form-inline"
          >
            <el-form-item label="登录" class="loginMsg"></el-form-item>
            <el-form-item prop="userName">
              <el-input
                type="text"
                v-model.trim="ruleForm.userName"
                auto-complete="off"
                placeholder="用户名"
              >
                <i slot="prefix" class="iconfont its_zhanghao"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                v-model.trim="ruleForm.password"
                auto-complete="off"
                placeholder="密码"
              >
                <i slot="prefix" class="iconfont its_mima"></i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="checked" class="rememberme">记住密码</el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSubmit" :loading="logining">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="footer">Copyright © 2020 四川英海瑞特科技有限公司</div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, getMenus, getUserLnlat } from '@/api/lib/api.js'
import md5 from 'js-md5'
import { menuRecursion } from '@/common/utils/index'
export default {
  data () {
    return {
      logining: false,
      checked: false,
      yhsdp: null,
      loading: false,
      ruleForm: {
        userName: '',
        password: ''
      },
      rules: {
        userName: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      popDoms: null
    }
  },
  methods: {
    handleSubmit (event) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.logining = true
          const password = md5(this.ruleForm.password)
          login({ loginName: this.ruleForm.userName, password: password })
            .then((res) => {
              if (res.code === 1000) {
                if (
                  !res.data.menuTree.subMenuTrees ||
                  res.data.menuTree.subMenuTrees.length === 0
                ) {
                  this.$message.error('暂无权限，请联系管理员配置')
                  this.logining = false
                  return
                }
                sessionStorage.setItem('username', this.ruleForm.userName)
                sessionStorage.setItem('realname', res.data.userName)
                sessionStorage.setItem('userId', res.data.id)
                sessionStorage.setItem('token', res.data.token)
                sessionStorage.setItem(
                  'menuList',
                  JSON.stringify(res.data.menuTree)
                )
                localStorage.setItem('onlineRemind', res.data.vehicleUp)
                localStorage.setItem('outlineRemind', res.data.vehicleDown)

                this.$store.dispatch('addUserInfo', res.data)
                this.$store.dispatch('add_Menus', res.data.menuTree)
                this.$message.success('登录成功')
                this.$router.push('/platform')
                // this.getUserLnlat(res.data.companyIdsList)
              } else {
                this.$message.error(res.msg)
              }
              this.logining = false
            })
            .catch((error) => {
              console.log(error)
              setTimeout(() => {
                this.logining = false
              }, 1000)
            })
          if (this.checked) {
            // 保存用户名和密码
            localStorage.setItem(
              'userPwd',
              JSON.stringify({
                name: this.ruleForm.userName,
                pwd: this.ruleForm.password
              })
            )
          } else {
            localStorage.removeItem('userPwd')
          }
        } else {
          console.log('error submit!')
          return false
        }
      })
    },

    /** 获取用户设置经纬度 */
    getUserLnlat (ids) {
      getUserLnlat({ companyIds: ids }).then((res) => {
        if (res.data.length > 0) {
          this.$store.dispatch('addUserLngLat', res.data[0])
        }
      })
    }
  },
  created () {
    localStorage.removeItem('vuex')
    this.$store.dispatch('clear_all')
    this.$store.dispatch('clear_MenuCtr')
    this.$store.dispatch('clear_user')
    this.$store.dispatch('clear_Menus')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('menuList')
    sessionStorage.removeItem('username')
    sessionStorage.removeItem('realname')
    localStorage.removeItem('onlineRemind')
    localStorage.removeItem('outlineRemind')
  },
  mounted () {
    const user = localStorage.getItem('userPwd')
    if (user) {
      this.ruleForm.userName = JSON.parse(user).name
      this.ruleForm.password = JSON.parse(user).pwd
      this.checked = true
    }
    document.onkeydown = (event) => {
      var e = event || window.event || arguments.callee.caller.arguments[0]
      if (e && e.keyCode === 13 && this.$route.path === '/login') {
        // enter 键
        this.handleSubmit()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #8d5783;
  background: -webkit-linear-gradient(
    left,
    #8d5783,
    #0056fd
  ); /* Safari 5.1-6.0 */
  background: -o-linear-gradient(right, #8d5783, #0056fd); /* Opera 11.1-12.0 */
  background: -moz-linear-gradient(
    right,
    #8d5783,
    #0056fd
  ); /* Firefox 3.6-15 */
  background: linear-gradient(to right, #8d5783, #0056fd); /* 标准语法 */
  position: relative;
  .container-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    @keyframes bounce1 {
      from {
        transform: scale3d(0.84, 0.85, 0.87) translate3d(0, 0, 0);
      }
      to {
        transform: scale3d(0.9, 0.9, 0.9) translate3d(-90px, 30px, -40px);
      }
    }
    @keyframes bounce2 {
      from {
        transform: scale3d(0.9, 0.9, 0.9) translate3d(0, 0, 0);
      }
      to {
        transform: scale3d(0.84, 0.85, 0.87) translate3d(-40px, -40px, 200px);
      }
    }
    @keyframes bounce3 {
      from {
        transform: scale3d(0.84, 0.85, 0.87) translate3d(-40px, -40px, 40px);
      }
      to {
        transform: scale3d(0.8, 0.8, 0.8) translate3d(0, 0, 0);
      }
    }
    .ball1 {
      animation: bounce1 2.5s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
    }
    .ball2 {
      animation: bounce2 2s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
    }
    .ball3 {
      animation: bounce3 3.5s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
    }
    & > p {
      position: relative;
      width: 30%;
      span {
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.1);
        position: absolute;
      }
    }
    & > p:nth-child(1) {
      span {
        width: 35vw;
        height: 35vw;
        top: -10vw;
        left: -2vw;
      }
    }
    & > p:nth-child(2) {
      span {
        width: 30vw;
        height: 30vw;
        top: 5vw;
        left: -13vw;
      }
    }
    & > p:nth-child(3) {
      flex: 1;
      span {
        width: 30vw;
        height: 30vw;
        bottom: -5vw;
        right: -11vw;
      }
    }
  }
  .platform-container {
    width: 80%;
    margin: 0 auto;
    color: #fff;
    z-index: 4;
    position: absolute;
    left: 10%;
    top: 0;
    .header {
      height: 20vh;
      margin-top: 10vh;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      .center {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        section {
          height: 9vh;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          img {
            height: 100%;
          }
          h3 {
            width: 100%;
            font-size: 2vw;
            font-weight: 400;
            margin: 0;
          }
          p {
            width: 100%;
            color: #f0f0f0;
            font-size: 0.73vw;
            letter-spacing: 1.11px;
            margin: 0;
          }
        }
      }
    }
    .main {
      height: 60vh;
      .login-page {
        width: 19.2vw;
        text-align: center;
        background: #fff;
        padding: 3.8vh 2.4vw;
        margin: 0 auto;
        position: relative;
        z-index: 99;
        box-shadow: 0 15px 40px -40px #000, 0 -15px 40px -40px #000;
        /deep/ .el-form-item__label {
          font-size: 24px;
          color: #000;
        }
        /deep/.el-checkbox:not(.is-disabled)
          .el-checkbox__input.is-checked
          .el-checkbox__inner {
          background-color: #336ffe;
          border-color: #336ffe;
        }
        .el-button {
          background: #336ffe;
          border-color: #336ffe;
          width: 19.2vw;
        }
        .el-checkbox {
          margin-left: 2px;
          text-align: left;
          width: 19.2vw;
        }
      }
    }
    .footer {
      height: 10vh;
      line-height: 10vh;
      text-align: center;
    }
  }
}
</style>
